import React, { useEffect, useState } from 'react';
import { useNetworkStatus } from 'react-adaptive-hooks/network';

import { screenLg } from '../utilties/helpers';

/*
    Theme context
    Used to store config relevant to the theme.

    screenType
        Wrapper that states if the application is currently running on a desktop or mobile device.
        This is used to control how the navigation is implementation.

    connectionType
        Gets connection type i.e. 2g, 3g, 4g etc.

    allowVideo
        Returns if video show be shown dependent on connection type.
*/

const defaultState = {
    screenType: screenLg.matches ? 'desktop' : 'mobile',
    menuOpen: false,
    toggleMenu: () => {},
    loading: false,
    toggleLoading: () => {},
    setLoading: () => {},
    notification: null,
    setNotification: () => {},
    connectionType: '4g',
    allowVideo: true,
};

const ThemeContext = React.createContext(defaultState);

export const ThemeProvider = ({ children }) => {
    const [screenType, setScreenType] = useState(screenLg.matches ? 'desktop' : 'mobile');
    const [menuOpen, setMenuOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState(null);
    
    // Get the network status
    const { connectionType } = useNetworkStatus(useNetworkStatus);

    // Show video if network cannot be detected
    let allowVideo = true;

    // disable video for slow networks
    switch(connectionType) {
        case 'slow-2g':
            allowVideo = false;
            break;
        case '2g':
            allowVideo = false;
            break;
        case '3g':
            allowVideo = true;
            break;
        default:
            allowVideo = true;
    }
    
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleLoading = () => {
        setLoading(!loading);
    };

    useEffect(() => {
        // Window listener to keep track of the screen resolution
        const handleResize = () => setScreenType(screenLg.matches ? 'desktop' : 'mobile');
        window.addEventListener('resize', handleResize);

        // Unmount (cleanup)
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ThemeContext.Provider value={{
            screenType,
            menuOpen,
            toggleMenu,
            loading,
            toggleLoading,
            setLoading,
            notification,
            setNotification,
            connectionType,
            allowVideo,
        }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContext;
