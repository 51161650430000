/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { Link } from 'gatsby';

// Overall site styles
import './src/assets/css/app.scss';

// Theme context
import { ThemeProvider } from './src/context/context.theme';

export const wrapRootElement = ({ element }) => (
    <>
        <noscript id="gatsby-noscript">
            This app works best with JavaScript enabled.<br/>
            Due to JavaScript based animations the homepage will not display correctly.<br/>
            Please nativate to <Link to="/racing-boats/">products</Link> to continue your journey.
        </noscript>
        <ThemeProvider>{element}</ThemeProvider>
    </>
);

// Intersection observer pollyfill
export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
        await import(`intersection-observer`);
    }
};