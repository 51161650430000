import React from 'react';
import { window } from './window-mock';

// Breakpoints inline with CSS
export const screenSm = window.matchMedia('(min-width: 576px)');
export const screenMd = window.matchMedia('(min-width: 768px)');
export const screenLg = window.matchMedia('(min-width: 992px)');
export const screenXl = window.matchMedia('(min-width: 1200px)');

// Used for see all links in selects, stored in a variable to make sure the format remains consistant
export const seeAll = { name: 'See All', id: 'seeAll' };

/**
 * Short copy to a specific number of characters without cutting off a word
 *
 * @param {*} str
 * @param {*} maxLength
 * @returns
 */
export function shortenCopy(str, maxLength) {
    let copy = str;

    if (typeof copy === 'string') {
        if (copy.length > maxLength) {
            copy = `${copy.substr(0, copy.lastIndexOf(' ', maxLength))}...`;
        }
    }

    return copy;
}

/**
 * Removes item from array by index
 *
 * @param {*} items
 * @param {*} i
 * @returns
 */
export function removeArrayItem(items, i) {
    return items.slice(0, i).concat(items.slice(i + 1, items.length));
}

/**
 * Prepares text in an array for animating
 *
 * @param {*} text
 * @returns
 */
export function prepTextForAnimation(text) {
    // Split text by word into an array
    const textArray = text.split(' ');

    // Add a space after each word
    for (var i = 0; i < textArray.length - 1; i++) {
        textArray[i] += ' ';
    }

    return textArray;
}

/**
 * Generates the HTML markup to display a status (i.e price/skill percentage)
 *
 * @param {*} full
 * @param {*} amount
 * @returns
 */
export function getStatus({ full, amount, title }) {
    const statusSections = [];

    for (let i = 0; i < full; i++) {
        statusSections.push(<li key={i} className={i < amount ? "c-status__item c-status__item--active" : "c-status__item"}></li>);
    }

    return <ul className="c-status" aria-label={`Shows ${amount} out of ${full} on ${title} scale`}>{statusSections}</ul>;
}

export function calculateDistance({ lat1, lon1, lat2, lon2 }) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}

function deg2rad(deg) {
    return deg * (Math.PI / 180)
}

/**
 * Checks to make sure content is actually content and not just blank space
 *
 * @param {*} content
 * @returns
 */
export function isThereContent(content) {
    let isContent = true;

    if (typeof content === 'string') {
        isContent = content.replace(/\s/g, '').length ? true : false;
    }

    return isContent;
}

/**
 * Sorts array alphabetically by name
 *
 * @export
 * @param {*} list
 * @returns
 */
export function sortAlphabeticallyByName(list) {
    const orderedList = list.sort(function(a, b){
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    return orderedList;
}
