/** 
 * Window Mock
 * 
 * In case `window` variable don’t exist, it will bean instance of `nothing`.
 * Allows scripts to run outside the browser and stops the build process of Gatsby crashing.
 */

const { Nothing } = require('nothing-mock');

const tempWindow = (typeof window !== 'undefined') ? window : Nothing;

module.exports.window = tempWindow;